// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: '/list',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr',
  // apiUrl: "http://localhost:4200",
  apiUrl: "https://8j10fklz4a.execute-api.ap-northeast-1.amazonaws.com/dev",
  UserPoolId: 'ap-northeast-1_2P9XPjBay',
  ClientId: '4itt6rvqj1n8h3deu5ndrgh8no',
  secretKey: 'ibasyoticketkey',
  IdentityPoolId: 'ap-northeast-1:d0d319a3-512b-40a4-a222-47a168cd0094',
  poolData: {
    UserPoolId: '4itt6rvqj1n8h3deu5ndrgh8no',
    ClientId: 'ap-northeast-1_2P9XPjBay',
  }
};

export default config;
export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

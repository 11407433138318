// project import

import partner from './partner';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [partner]
};

export default menuItems;

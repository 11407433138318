// assets
import { DashboardOutlined, HomeOutlined, MailOutlined, CommentOutlined, QuestionCircleOutlined, LogoutOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  HomeOutlined,
  MailOutlined,
  CommentOutlined,
  QuestionCircleOutlined,
  LogoutOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const user = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'list',
      title: 'チケット一覧',
      type: 'item',
      url: '/list',
      icon: icons.HomeOutlined,
      breadcrumbs: false
    },
    {
      id: 'chat',
      title: 'お問い合わせ',
      type: 'item',
      url: 'https://ibasho-ticket.channel.io/home',
      icon: icons.CommentOutlined,
      breadcrumbs: false,
      target: '_blank'
    },
    {
      id: 'logout',
      title: 'Logout',
      type: 'item',
      url: '/logout',
      icon: icons.LogoutOutlined,
      breadcrumbs: false
    }
    // {
    //   id: 'detail',
    //   title: 'バウチャー詳細',
    //   type: 'item',
    //   url: '/detail',
    //   icon: icons.MailOutlined,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'preview',
    //   title: 'プレビュー',
    //   type: 'item',
    //   url: '/preview',
    //   icon: icons.CommentOutlined,
    //   breadcrumbs: false
    // }
  ]
};

export default user;

// assets
import { DashboardOutlined, HomeOutlined, MailOutlined, CommentOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  HomeOutlined,
  MailOutlined,
  CommentOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const staff = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'tickets',
      title: 'チケット一覧',
      type: 'item',
      url: '/tickets',
      icon: icons.HomeOutlined,
      breadcrumbs: false
    },
    // {
    //   id: 'events',
    //   title: '処方状況一覧',
    //   type: 'item',
    //   url: '/events',
    //   icon: icons.MailOutlined,
    //   breadcrumbs: false
    // },
    {
      id: 'users',
      title: '相談者一覧',
      type: 'item',
      url: '/users',
      icon: icons.CommentOutlined,
      breadcrumbs: false
    }
    ,
    {
      id: 'logout',
      title: 'Logout',
      type: 'item',
      url: '/logout_staff',
      icon: icons.CommentOutlined,
      breadcrumbs: false
    }
    ,
    // {
    //   id: 'partners',
    //   title: '提携先一覧',
    //   type: 'item',
    //   url: '/partners',
    //   icon: icons.DashboardOutlined,
    //   breadcrumbs: false
    // }
  ]
};

export default staff;

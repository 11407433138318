import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import StaffLayout from 'layout/StaffLayout';

// render - dashboard
// const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const UserPage = Loadable(lazy(() => import('pages/staff/UserPage')));
const VoucherPage = Loadable(lazy(() => import('pages/staff/VoucherPage')));
const DetailPage = Loadable(lazy(() => import('pages/user/DetailPage')));


// ==============================|| MAIN ROUTING ||============================== //

const StaffRoutes = {
  path: '/',
  element: <StaffLayout />,
  children: [
    {
      path: '/users',
      element: <UserPage />
    },
    {
      path: '/',
      element: <UserPage />
    },
    {
      path: '/preview/:id',
      element: <DetailPage />
    },
    // {
    //   path: '/partners',
    //   element: <Color />
    // },
    // {
    //   path: '/tickets',
    //   element: <Color />
    // },
    {
      path: '/tickets',
      element: <VoucherPage />
    }
  ]
};

export default StaffRoutes;

import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import StaffRoutes from './StaffRoutes';
import UserRoutes from './UserRoutes';
import PartnerRoutes from './PartnerRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([StaffRoutes, LoginRoutes, UserRoutes, PartnerRoutes]);
}

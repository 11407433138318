// project import
import staff from './staff';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [staff]
};

export default menuItems;

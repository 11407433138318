import { Outlet } from 'react-router-dom';
import 'assets/css/index.css';
import { useEffect, useState } from 'react';
// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const [base, setBase] = useState("");
  useEffect(() => {
    var url = window.location.href;
    if (url.match(/login_staff/) || url.match(/logout_staff/)) {
      setBase("staff");
    } else if (url.match(/login_partner/) || url.match(/logout_partner/)) {
      setBase("partner");
    } else {
      setBase("");
    }
  }, []);
  return (

    base == "staff" ?
      <div className='staff'>
        <Outlet />
      </div> :
      base == "partner" ?
        <div className='partner'>
          <Outlet />
        </div> :
        <div className='user'>
          <Outlet />
        </div>

  )
};

export default MinimalLayout;

import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// render - login
const NotExist = Loadable(lazy(() => import('pages/authentication/NotExist')));
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthLoginStaff = Loadable(lazy(() => import('pages/authentication/LoginStaff')));
const AuthLoginPartner = Loadable(lazy(() => import('pages/authentication/LoginPartner')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const AuthRegisterOther = Loadable(lazy(() => import('pages/authentication/RegisterOther')));
const Term = Loadable(lazy(() => import('pages/authentication/Term')));
const Password = Loadable(lazy(() => import('pages/authentication/Password')));
const NewPassword = Loadable(lazy(() => import('pages/authentication/NewPassword')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'login_staff',
      element: <AuthLoginStaff />
    },
    {
      path: 'login_partner',
      element: <AuthLoginPartner />
    },
    {
      path: 'register/:id',
      element: <AuthRegister />
    }
    ,
    {
      path: 'register_other',
      element: <AuthRegisterOther />
    }
    ,
    {
      path: 'logout',
      element: <AuthLogin />
    },
    {
      path: 'logout_staff',
      element: <AuthLoginStaff />
    },
    {
      path: 'logout_partner',
      element: <AuthLoginPartner />
    },
    {
      path: 'terms',
      element: <Term />
    },
    {
      path: 'password',
      element: <Password />
    },
    {
      path: 'new_password',
      element: <NewPassword />
    },
    {
      path: '*',
      element: <NotExist />
    },
  ]
};

export default LoginRoutes;

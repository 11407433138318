// project import

import user from './user';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [user]
};

export default menuItems;

// material-ui


/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {


  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Mantis" width="100" />
     *
     */
    <img src={"https://storage.googleapis.com/studio-design-asset-files/projects/p6aoxMPkWR/s-297x252_79077cdc-46f0-401f-a5f0-38a3966bb7e7.svg"} alt="いばしょチケット" width="30" />
  );
};

export default Logo;

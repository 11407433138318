// assets
import { ChromeOutlined, QuestionOutlined } from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const partner = {
  id: 'support',
  title: 'Support',
  type: 'group',
  children: [
    {
      id: 'sample-page',
      title: '処方実績ダウンロード',
      type: 'item',
      url: '/partner',
      icon: icons.ChromeOutlined
    },
    {
      id: 'logout',
      title: 'Logout',
      type: 'item',
      url: '/logout_partner',
      icon: icons.CommentOutlined,
      breadcrumbs: false
    }
  ]
};

export default partner;

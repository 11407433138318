import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import PartnerLayout from 'layout/PartnerLayout';


const ExportPage = Loadable(lazy(() => import('pages/partner/ExportPage')));


// ==============================|| MAIN ROUTING ||============================== //

const PartnerRoutes = {
  path: '/',
  element: <PartnerLayout />,
  children: [
    {
      path: 'partner',
      element: <ExportPage />
    },
  ]
};

export default PartnerRoutes;

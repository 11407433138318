import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import UserLayout from 'layout/UserLayout';

// render - dashboard
const ListPage = Loadable(lazy(() => import('pages/user/ListPage')));

const DetailPage = Loadable(lazy(() => import('pages/user/DetailPage')));





// ==============================|| MAIN ROUTING ||============================== //

const StaffRoutes = {
  path: '/',
  element: <UserLayout />,
  children: [
    {
      path: 'list',
      element: <ListPage />
    },
    {
      path: 'detail/:id',
      element: <DetailPage />
    },

  ]
};

export default StaffRoutes;
